import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import ShareKit from '../../components/ShareKit';
import { ExpandedGallery } from '../../components/responsive/organisms/ExpandedGallery/ExpandedGallery';
import { conf } from '../../domain/services/configService';
import { getTaxonomies } from '../../domain/utils/contentUtils';
import { ContentMainTitle } from '../../components/ContentMainTitle';
import { TagList } from '../../components/responsive/molecules/TagList';
import { theme } from '../../domain/theme';
import { SponsoredAdditionalText } from '../../components/SponsoredAdditionalText';
import { SponsoredBar } from '../../components/SponsoredBar';
import { Recommendation } from '../../components/Recommendation';

const nodeTypePhotoGallery = css`
  & .sponsor_additional_text {
    margin: 32px 60px;
    width: unset;
  }
`;
const ngGalleryPage = css`
  position: relative !important;
  height: auto;
  width: 100%;
  overflow: visible;
  /*z-index: 1;*/
`;
export const PhotoGalleryInner = ({ content, isTransported }) => {
  const articleTag = useRef(null);
  const tags = getTaxonomies(content, { includeParents: true });
  const styles = {
    tagList: css`
      padding: 0;
      margin: 30px 15px;
      ${theme.mq.tablet} {
        margin: 30px 60px;
      }
    `
  };
  return (
    <>
      {!isTransported && (
        <ShareKit
          type="article"
          content={content}
          twitterHandle={conf.menus.menuSocialMedia.twitterHandle}
          color="black"
          useBrowserUrl
        />
      )}
      <article
        data-node-id={content.id}
        // eslint-disable-next-line react/no-unknown-property
        about={content.url?.path}
        className="node node--type-photo-gallery ng-gallery-page"
        css={[nodeTypePhotoGallery, ngGalleryPage]}
        ref={articleTag}
      >
        <SponsoredBar content={content} articleRef={articleTag} />
        <ContentMainTitle content={content} />

        <ExpandedGallery
          isPage
          imageEntities={content.images}
          content={content}
        />
        <SponsoredAdditionalText content={content} />
        <div css={styles.tagList}>
          <TagList tags={tags} />
        </div>
      </article>

      <Recommendation
        content={content}
        isTransported={isTransported}
        isExpanded
      />
    </>
  );
};

PhotoGalleryInner.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  isTransported: PropTypes.bool.isRequired
};
